<template>
  <section class="inset mt-0 bg-black text-salmon">
    <div class="row-10">
      <div class="offset-1 col-8 md:offset-2 md:col-6 lg:offset-3 lg:col-5">
        <h2 class="immobilien-title" v-html="payload.title" />
      </div>
    </div>

    <div class="immobilien-content row-10">
      <div class="immobilien-text-links offset-1 col-8 md:offset-2 md:col-3 lg:offset-3 lg:col-2">
        <p class="immobilien-text" v-html="payload.textlinks" />
      </div>
      <div class="offset-1 col-8 md:offset-0 md:col-3 lg:col-2 md:pl-12">
        <p class="immobilien-text" v-html="payload.textrechts" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: Object },
};
</script>

<style lang="scss" scoped>

:deep(.immobilien-text) {
  ul {
    margin: 0 0 0 px(22);
  }
}
</style>
